var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[(_vm.isSpaceAdmin && _vm.isDistributedInstance && _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE && !_vm.isSpaceArchived)?_c('v-banner',{staticClass:"mb-5",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"target":"_blank","color":"primary"},on:{"click":_vm.goToMaster}},[_vm._v("Return to Master instance")])]},proxy:true}],null,false,1387382373)},[_c('v-avatar',{attrs:{"slot":"icon","color":"white","size":"30"},slot:"icon"},[_c('v-icon',{attrs:{"large":"","color":"info"}},[_vm._v("info")])],1),_c('span',{staticClass:"subtitle-1"},[_vm._v(" You can recall files and apps distributed to all students or if you wish to make updates, then return to the Master instance and distribute the changed materials. ")])],1):_vm._e(),(_vm.isSpaceArchived && _vm.isSpaceAdmin && !_vm.isDistributedInstance)?_c('v-banner',{staticClass:"mb-5",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"href":"https://docs.nuvolos.cloud/user-guides/education-guides/archiving-your-course","elevation":"0","target":"_blank","color":"primary"}},[_vm._v(" Learn more ")])]},proxy:true}],null,false,1879308530)},[_c('v-avatar',{attrs:{"slot":"icon","color":"white","size":"30"},slot:"icon"},[_c('v-icon',{attrs:{"large":"","color":"info"}},[_vm._v("info")])],1),_c('span',{staticClass:"subtitle-1"},[_vm._v(" This space is archived. You may "),_c('router-link',{attrs:{"to":{
                    name: _vm.getCloneRoute,
                    oid: this.$route.oid,
                    query: {
                        sid: this.$route.params.sid
                    }
                }}},[_vm._v(" create a new space ")]),_vm._v(" reusing the contents of the Master instance, download files or distribute files/tables/applications to other spaces. ")],1)],1):_vm._e(),_c('div',{staticStyle:{"height":"100%"}},[_c('TheSnapshotAppIframe'),_c('TheSnapshotFileList',{style:(_vm.$route.name !== 'snapshot-files' ? { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '0px' } : {})}),_c('v-container',{style:(_vm.$route.name === 'snapshot-files' || _vm.$router.name === 'app-open'
                    ? { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '0px' }
                    : {}),attrs:{"fluid":""}},[_c('router-view',{attrs:{"name":"notifications"}}),_c('transition',{attrs:{"name":"fadeonly","mode":"out-in"}},[_c('keep-alive',{attrs:{"include":['snapshot-tables']}},[_c('router-view')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }